import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss'],
})
export class QrComponent implements OnInit {
  @Input()
  url = '';
  @Input()
  size = 130;

  constructor() {}

  ngOnInit() {}
}
