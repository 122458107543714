import { Component, OnInit, Input } from '@angular/core';
import { QrService } from '../../services/qr.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  @Input() demo = '';
  @Input() informationBase: any;
  qr1: string;
  qr2: string;

  constructor(private qrService: QrService) { }

  ngOnInit() {
    this.generateQr();
  }

  private generateQr() {
    switch (this.demo) {
      case 'acme':
        this.qr1 = environment.qrValues.university;
        break;
      default:
        break;
    }
  }
}