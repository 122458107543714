import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CertificateService } from '@app/services/certificate.service';
import {
  INFO_BASE_SITE,
  TIMER_REQUEST,
} from '@app/util/constants';
import { environment } from '@environments/environment';
import * as moment from 'moment';
import { interval } from 'rxjs';
import { flatMap, startWith } from 'rxjs/operators';

import { IShareCertificate, ShareCertificate } from './main-melon-university.model';
import { MatGridListModule } from '@angular/material/grid-list';

@Component({
  selector: 'app-main-melon-university',
  templateUrl: './main-melon-university.component.html',
  styleUrls: ['./main-melon-university.component.scss']
})
export class MainMelonUniversityComponent implements OnInit {
  displayedColumns = [];
  dataSource: IShareCertificate[];
  // dataSource: IShareCertificate[] = MOCK_ELEMENT_DATA_POLICE;
  isAcmeRoute = false;
  informationBase: any = {};
  public lottieConfig: Object;
  demo = '';

  constructor(
    public dialog: MatDialog,
    private router: Router
  ) {
    this.loadInformationBase();
    this.loadConfigLottie();
  }

  ngOnInit() {

  }

  loadInformationBase() {
    let url: string = this.router.url;
    let path: string = url.substr(1).trim();
    this.demo = path;

    switch (path) {
      case 'acme':
        this.isAcmeRoute = true;
        this.displayedColumns = [
          'sharedBy',
          'receivedBy',
          'recentActivity',
          'status',
        ];
        this.informationBase = INFO_BASE_SITE[0];
        break;
      default:
        break;
    }
  }

  loadConfigLottie() {
    this.lottieConfig = {
      path: this.informationBase.svgStatus,
      autoplay: true,
      loop: false,
    };
  }
}
