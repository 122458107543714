import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QRCodeModule } from 'angular2-qrcode';
import { LottieAnimationViewModule } from 'ng-lottie';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HistoryComponent } from './components/history/history.component';
import { QrComponent } from './components/qr/qr.component';
import { MainMelonUniversityComponent } from './pages/main-melon-university/main-melon-university.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { HtmlPipe } from './pipes/html.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainMelonUniversityComponent,
    PageNotFoundComponent,
    HtmlPipe,
    HistoryComponent,
    QrComponent,
  ],
  imports: [
    LottieAnimationViewModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    MatTableModule,
    MatButtonModule,
    HttpClientModule,
    FlexLayoutModule,
    QRCodeModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})

export class AppModule {}
