import { version } from '../../package.json';

export const environment = {
  production: true,
  urlService: {
    acme: 'https://acme.demo.kaytrust.id/users/enterprise',
  },
  qrValues: {
    university: "openid-credential-offer://?credential_offer_uri=https://kaytown-melon-university.azurewebsites.net/api/v1/issuer/offers"
  },
  VERSION: version,
};