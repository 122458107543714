import { IShareCertificate } from '@app/pages/main-melon-university/main-melon-university.model';
import { trigger, sequence, animate, transition, style } from '@angular/animations';
import { environment } from '@environments/environment';

export const TIMER_REQUEST = 50000;
export const HEIGHT_TABLE = 520;

export const INFO_BASE_SITE: any = [{
    id: 'acme',
    logo: '../../../assets/images/logo-acme-enterprise.png',
    titleHeader: 'Melon University',
    title: 'Melon University',
    svgStatus: '../../../assets/svg/json/verify_acme.json',
    imgBox: '../../../assets/images/undraw-folder.png',
    textBox: 'acme.job.description',
    logoBox: '../../../assets/images/logo-acme-university.png',
    iconReceived: '../../../assets/images/icon-acme-enterprise.png',
    urlService: environment.urlService.acme
}];

export const ROWS_ANIMATION_X =
    trigger('rowsAnimation', [
        transition('void => *', [
            style({ height: '*', opacity: '0', transform: 'translateX(-550px)', 'box-shadow': 'none' }),
            sequence([
                animate(".35s ease", style({ height: '*', opacity: '.2', transform: 'translateX(0)', 'box-shadow': 'none' })),
                animate(".35s ease", style({ height: '*', opacity: 1, transform: 'translateX(0)' }))
            ])
        ])
    ]);

export const ROWS_ANIMATION_Y =
    trigger('rowsAnimation', [
        transition('void => *', [
            style({ height: '*', opacity: '0', transform: 'translateY(-550px)', 'box-shadow': 'none' }),
            sequence([
                animate(".35s ease", style({ height: '*', opacity: '.2', transform: 'translateY(0)', 'box-shadow': 'none' })),
                animate(".35s ease", style({ height: '*', opacity: 1, transform: 'translateY(0)' }))
            ])
        ])
    ]);
